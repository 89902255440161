import { Decimal } from 'decimal.js-light'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import * as C from 'fp-ts/Console'
import * as E from 'fp-ts/Either'
import * as IO from 'fp-ts/IO'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/ReadonlyArray'
import { LazyArg, pipe } from 'fp-ts/function'
import { floatFromString } from 'fp-ts-std/Number'
import { create } from 'zustand'
import { Handler, OptionalType } from '@digital-magic/ts-common-utils'
import { personalizationStorageKey } from '@constants/configuration'
import { Price } from '@api/endpoints/types'
import {
  HouseOptionValueView,
  HouseOptionView,
  HouseStyleView,
  sortHouseStyleImagesByOrderByOrderNumber
} from '@api/endpoints/buildings/houses'
import { LayoutTypeStyleId } from '@api/endpoints/buildings/layoutTypes'
import { OptionId, OptionValueId, filterDisabledAndSortOptions } from '@api/endpoints/buildings/options'
import { ContactsFormState } from './types'

/*
type AvailableLayoutTypeStyle = Readonly<{
  layoutTypeStyleId: LayoutTypeStyleId
  additionalPrice: number
  images: LayoutTypeStyleImages
}>

type AvailableOptionValue = Readonly<{

}>

type AvailableOption = Readonly<{
  optionId: OptionId,
  name: TranslatedString,
  multiValue: boolean,
  values: ReadonlyArray<>
}>
*/
type SelectedHouseOption = HouseOptionView & Readonly<{ optionPrice: number }>

type AvailableHouseOptions = Readonly<{
  styles: ReadonlyArray<HouseStyleView>
  options: ReadonlyArray<HouseOptionView>
  basePrice: number
}>

type State = Readonly<{
  availableHouseOptions: AvailableHouseOptions
  selectedLayoutTypeStyleId?: LayoutTypeStyleId
  selectedOptions: ReadonlyArray<SelectedHouseOption>
  highlightedOptionIds: ReadonlyArray<OptionId>
  customer?: ContactsFormState
  totalPrice: number
}>

type Actions = Readonly<{
  setAvailableHouseOptions: Handler<AvailableHouseOptions>
  setSelectedLayoutTypeStyleId: Handler<LayoutTypeStyleId>
  setSelectedOptionValueIds: Handler<ReadonlyArray<OptionValueId>>
  setSelectedOptionValueId: (optionId: OptionId, optionValueId: OptionValueId) => void
  addSelectedOptionValueId: Handler<OptionValueId>
  removeSelectedOptionValueId: Handler<OptionValueId>
  setupHighlightedOptions: IO.IO<OptionalType<OptionId>>
  removeHighlightedOption: Handler<OptionId>
  setCustomer: Handler<ContactsFormState>
  reset: IO.IO<void>
}>

export const add = (value1: number, value2: number): number => new Decimal(value1).add(new Decimal(value2)).toNumber()

const getSelectedHouseStyleView = (state: State): O.Option<HouseStyleView> =>
  pipe(
    O.fromNullable(state.selectedLayoutTypeStyleId),
    O.chain((selectedLayoutTypeStyleId) =>
      A.findFirst((s: HouseStyleView) => s.layoutTypeStyleId === selectedLayoutTypeStyleId)(
        state.availableHouseOptions.styles
      )
    )
  )

const getOrElseWithLog =
  <T>(defaultValue: LazyArg<T>) =>
  (value: E.Either<string, T>): IO.IO<T> =>
    pipe(IO.of(value), IO.map(E.mapLeft((e) => pipe(IO.of(e), IO.tap(C.log)))), IO.map(E.getOrElse(defaultValue)))

const parsePrice =
  <T extends Price>(errorMsg: (param: T) => string) =>
  (price: T): E.Either<string, number> =>
    pipe(
      price,
      floatFromString,
      E.fromOption(() => errorMsg(price))
    )

const getSelectedHouseStylePrice = (state: State): E.Either<string, number> =>
  pipe(
    getSelectedHouseStyleView(state),
    E.fromOption(() => 'Selected house style price must be set'),
    E.chain((s: HouseStyleView) =>
      pipe(
        s.additionalPrice,
        parsePrice(
          (price: Price): string =>
            `Unable to parse price for selected house style: styleId: ${s.layoutTypeStyleId}, price: ${price}`
        )
      )
    )
  )

// TODO: This function is used not really correctly
const getOptionValuePrice = (v: HouseOptionValueView): IO.IO<number> =>
  pipe(
    v.price,
    parsePrice((price) => `Unable to parse price for option value: optionValueId=${v.optionValueId}, price=${price}`),
    getOrElseWithLog(() => 0)
  )

const buildSelectedOptionValues = (
  state: State,
  selectedOptionValues: ReadonlyArray<OptionValueId>
): ReadonlyArray<SelectedHouseOption> =>
  pipe(
    state.availableHouseOptions.options,
    A.map((o: HouseOptionView) =>
      pipe(
        o.values.filter((v: HouseOptionValueView) => selectedOptionValues.includes(v.optionValueId)),
        (values: Array<HouseOptionValueView>) => ({
          ...o,
          values,
          optionPrice: values.reduce((acc, v) => add(acc, getOptionValuePrice(v)()), 0)
        })
      )
    ),
    A.filter((o: HouseOptionView) => A.isNonEmpty(o.values))
  )

const buildHighlightedOptions = (state: State): ReadonlyArray<OptionId> =>
  pipe(
    state.availableHouseOptions.options,
    //A.filter((o) => A.isEmpty(o.values) && o.multiValue === false),
    A.filter((o: HouseOptionView) => o.multiValue === false),
    A.map((o) => o.optionId),
    A.filter((optionId) => !state.selectedOptions.some((o) => o.optionId === optionId))
  )

// TODO: Verify correctness
const calculateTotalPrice = (state: State): IO.IO<number> =>
  pipe(
    getSelectedHouseStylePrice(state),
    getOrElseWithLog(() => 0),
    IO.map((stylePrice: number) => add(state.availableHouseOptions.basePrice, stylePrice)),
    IO.map((v: number) =>
      pipe(
        state.selectedOptions,
        A.map((o) => o.optionPrice),
        A.reduce(v, add)
      )
    )
  )

const setAvailableHouseOptions = (state: State, availableHouseOptions: AvailableHouseOptions): Partial<State> =>
  pipe(
    {
      options: filterDisabledAndSortOptions(availableHouseOptions.options),
      styles: sortHouseStyleImagesByOrderByOrderNumber(availableHouseOptions.styles),
      basePrice: availableHouseOptions.basePrice
    }, // TODO: Improve type-safety, type check is weak here
    (availableHouseOptions) => ({
      availableHouseOptions,
      selectedOptions: [],
      selectedLayoutTypeStyleId: availableHouseOptions.styles[0]?.layoutTypeStyleId,
      highlightedOptionIds: [],
      //customer: undefined, // Customer may remain the same, no need to reset it
      totalPrice: calculateTotalPrice({ ...state, availableHouseOptions })()
    })
  )

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setSelectedLayoutTypeStyleId = (state: State, selectedLayoutTypeStyleId: LayoutTypeStyleId): Partial<State> => ({
  selectedLayoutTypeStyleId,
  totalPrice: calculateTotalPrice({ ...state, selectedLayoutTypeStyleId })()
})

const setSelectedOptionValueIds = (
  state: State,
  selectedOptionValueIds: ReadonlyArray<OptionValueId>
): Partial<State> =>
  pipe(buildSelectedOptionValues(state, selectedOptionValueIds), (selectedOptions) => ({
    selectedOptions,
    totalPrice: calculateTotalPrice({ ...state, selectedOptions })()
  }))

const getSelectedOptionValueIds = (state: State): ReadonlyArray<OptionValueId> =>
  state.selectedOptions.flatMap((o) => o.values.map((v) => v.optionValueId))

const getSelectedOptionValueIdsExceptOptionId = (state: State, optionId: OptionId): ReadonlyArray<OptionValueId> =>
  state.selectedOptions.filter((o) => o.optionId != optionId).flatMap((o) => o.values.map((v) => v.optionValueId))

const emptyState: State = {
  availableHouseOptions: {
    options: [],
    styles: [],
    basePrice: 0
  },
  selectedLayoutTypeStyleId: undefined,
  selectedOptions: [],
  highlightedOptionIds: [],
  customer: undefined,
  totalPrice: 0
}

export const usePersonalizationStore = create<State & Actions>()(
  devtools(
    persist(
      (set, get) => ({
        ...emptyState,
        setAvailableHouseOptions: (availableHouseOptions) =>
          set((state) => setAvailableHouseOptions(state, availableHouseOptions)),
        setSelectedLayoutTypeStyleId: (selectedLayoutTypeStyleId) =>
          set((state) => setSelectedLayoutTypeStyleId(state, selectedLayoutTypeStyleId)),
        setSelectedOptionValueIds: (selectedOptionValueIds) =>
          set((state) => setSelectedOptionValueIds(state, selectedOptionValueIds)),
        setSelectedOptionValueId: (optionId, optionValueId) =>
          set((state) =>
            setSelectedOptionValueIds(
              state,
              getSelectedOptionValueIdsExceptOptionId(state, optionId).concat(optionValueId)
            )
          ),
        addSelectedOptionValueId: (selectedOptionValueId) =>
          set((state) =>
            setSelectedOptionValueIds(state, getSelectedOptionValueIds(state).concat(selectedOptionValueId))
          ),
        removeSelectedOptionValueId: (selectedOptionValueId) =>
          set((state) =>
            setSelectedOptionValueIds(
              state,
              getSelectedOptionValueIds(state).filter((id) => id !== selectedOptionValueId)
            )
          ),
        setupHighlightedOptions: () =>
          pipe(
            set((state) => ({ highlightedOptionIds: buildHighlightedOptions(state) })),
            () => get().highlightedOptionIds[0]
          ),
        removeHighlightedOption: (highlightedOptionId) =>
          set((state) => ({
            highlightedOptionIds: state.highlightedOptionIds.filter((o) => o !== highlightedOptionId)
          })),
        setCustomer: (customer) => set({ customer: customer }),
        reset: () => set(emptyState)
      }),
      {
        name: personalizationStorageKey,
        storage: createJSONStorage(() => sessionStorage)
      }
    )
  )
)
