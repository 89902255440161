import styled, { css } from 'styled-components'
import { styled as materialStyled } from '@mui/system'
import { spacing } from '@mui/system'

export const HighlightedTextStyled = styled(materialStyled('span')(spacing))(
  ({ theme }) => css`
    display: block;
    font-size: ${theme.global.typography.size['size-34']};
    font-weight: ${theme.typography.fontWeightRegular}; // 400
    color: ${theme.colors.brown};

    ${theme.breakpoints.down('sm')} {
      font-size: ${theme.global.typography.size['size-22']};
    }
  `
)

export const HighlightedTitleStyled = styled(materialStyled('h1')(spacing))(
  ({ theme }) => css`
    display: block;
    font-size: ${theme.global.typography.size['size-34']};
    font-weight: ${theme.typography.fontWeightRegular}; // 400
    color: ${theme.colors.brown};
    margin: ${theme.spacing(0, 0, 3, 0)};
  `
)
