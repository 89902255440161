import * as React from 'react'
import { TitledSectionStyle } from '@pages/Customer/Booking/TitledSection/TitledSection.style'

type Props = Readonly<{
  title: React.ReactNode
  children: React.ReactNode
}>

export const TitledSection: React.FC<Props> = ({ title, children }) => {
  return (
    <TitledSectionStyle>
      <div className="title">
        <h2>{title}</h2>
      </div>
      <div className="content">{children}</div>
    </TitledSectionStyle>
  )
}
