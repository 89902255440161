import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { priceCurrency } from '@constants/configuration'
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter'
import { useTranslatedValue } from '@hooks/useTranslatedValue'
import { Fade } from '@mui/material'
import { DetailList, DetailListItem } from '@controls/DetailList'
import { TitledSection } from '@pages/Customer/Booking/TitledSection'
import { HighlightedText } from '@pages/Customer/Common/Higlighted'
import { usePersonalizationStore } from '../../usePersonalizationStore'
import { SummaryStyled } from './Summary.styles'

export const Summary: React.FC = () => {
  const { t } = useTranslation()
  const translateValue = useTranslatedValue()
  const formatCurrency = useCurrencyFormatter()

  const selectedOptions = usePersonalizationStore((s) => s.selectedOptions)

  // TODO: Verify correctness
  const detailItems: ReadonlyArray<DetailListItem> = React.useMemo(
    () =>
      selectedOptions.map((o) => ({
        label: `${translateValue(o.name)} (${o.values.map((v) => translateValue(v.name)).join(', ')})`,
        value: formatCurrency(o.optionPrice, priceCurrency)
      })),
    [selectedOptions, translateValue, formatCurrency]
  )

  return detailItems.length > 0 ? (
    <Fade in>
      {/*TODO: This div needs to make Fade to not throw exceptions*/}
      <div>
        <SummaryStyled bgType="light">
          <TitledSection title={<HighlightedText>{t('pages.booking.personalization.summary.title')}</HighlightedText>}>
            <DetailList items={detailItems} />
          </TitledSection>
        </SummaryStyled>
      </div>
    </Fade>
  ) : null
}
