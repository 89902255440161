import * as React from 'react'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import { hasValue } from '@digital-magic/ts-common-utils'
import { toRequiredFieldLabel } from '@utils/formatting-utils'
import { CustomerType } from '@api/endpoints/bookings'
import { useGetLanguage } from '@hooks/useGetLanguage'
import { FormHelperText } from '@mui/material'
import { Box } from '@mui/system'
import { Button } from '@controls/Button'
import { FormCheckbox1, FormTextFieldStyled, useFormZod } from '@controls/Form'
import { Link } from '@controls/Link'
import { Text } from '@controls/Text'
import { MenuItemEntry } from '@controls/types'
import { buildEnumOptions } from '@controls/utils'
import { ContactsFormState } from '../../../types'
import { ContactsFormStyled } from './styles'

type Props = Readonly<{
  defaultValues?: ContactsFormState
  onSubmit: React.Dispatch<ContactsFormState>
  loading?: boolean
  adminRole?: boolean
}>

export const ContactsForm: React.FC<Props> = ({ defaultValues, onSubmit, loading = false, adminRole = false }) => {
  const { t } = useTranslation()
  const language = useGetLanguage()

  const ContactsFormValues = ContactsFormState.and(
    z.object({
      agreeWithTerms: z.boolean().refine((v) => v === true, { message: t('pages.booking.form.errors.terms_consent') })
    })
  ) //.refine((v) => v.agreeWithTerms)
  type ContactsFormValues = z.infer<typeof ContactsFormValues>

  const form = useFormZod({
    schema: ContactsFormValues,
    defaultValues
  })

  const customerTypeOptions: ReadonlyArray<MenuItemEntry<CustomerType>> = React.useMemo(
    () => buildEnumOptions(CustomerType, (v) => t(`pages.booking.form.customer_type.${v}`)),
    [t]
  )

  React.useEffect(() => {
    form.reset(defaultValues)
  }, [defaultValues, form])

  const customerType = form.watch('customerType')

  return (
    <ContactsFormStyled>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormTextFieldStyled
          form={form}
          name="customerType"
          label={t('pages.booking.form.customer_type.label')}
          select
          selectOptions={customerTypeOptions}
          disabled={loading}
        />
        <FormTextFieldStyled
          form={form}
          name="email"
          label={toRequiredFieldLabel(t('pages.booking.form.email'))}
          disabled={loading}
        />
        <FormTextFieldStyled
          form={form}
          name="phone"
          label={toRequiredFieldLabel(t('pages.booking.form.phone'))}
          disabled={loading}
        />
        {customerType === 'Person' ? (
          <>
            <FormTextFieldStyled
              form={form}
              name="fullName"
              label={toRequiredFieldLabel(t('pages.booking.form.full_name'))}
              disabled={loading}
            />
            <FormTextFieldStyled
              form={form}
              name="personalCode"
              label={toRequiredFieldLabel(t('pages.booking.form.personal_code'))}
              disabled={loading}
            />
          </>
        ) : (
          <>
            <FormTextFieldStyled
              form={form}
              name="companyCode"
              label={toRequiredFieldLabel(t('pages.booking.form.company_code'))}
              disabled={loading}
            />
            <FormTextFieldStyled
              form={form}
              name="companyName"
              label={toRequiredFieldLabel(t('pages.booking.form.company_name'))}
              disabled={loading}
            />
            <FormTextFieldStyled
              form={form}
              name="vatNumber"
              label={toRequiredFieldLabel(t('pages.booking.form.vat_number'))}
              disabled={loading}
            />
          </>
        )}
        {!adminRole && (
          <>
            <Box display="flex">
              <Box mr={1} alignSelf="center">
                <FormCheckbox1
                  form={form}
                  name="agreeWithTerms"
                  className="terms-checkbox"
                  disabled={loading}
                  parseError={() => ''}
                />
              </Box>
              <Box>
                <Text size="size-14" weight="regular">
                  {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                  {t('pages.booking.form.read_conditions.text', { button: t('pages.booking.form.submit') })}{' '}
                  <Link href={`/docs/terms_data_processing_${language.toLowerCase()}.pdf`} target="_blank">
                    {t('pages.booking.form.read_conditions.link')}
                  </Link>
                </Text>
              </Box>
            </Box>
            {hasValue(form.formState.errors.agreeWithTerms) && (
              <FormHelperText error={true}>{t('pages.booking.form.errors.terms_consent')}</FormHelperText>
            )}
          </>
        )}
        <Button type="submit" loading={loading}>
          {t('pages.booking.form.submit')}
        </Button>
      </form>
    </ContactsFormStyled>
  )
}
