import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { hasValue, parseIntNanSafe } from '@digital-magic/ts-common-utils'
import { HouseId, useGetBookingItem } from '@api/endpoints/buildings/houses'
import { eqOptionValueIdArray, getSortedOptionValueIds } from '@api/endpoints/buildings/options'
import { useDefaultErrorHandler } from '@hooks/useDefaultErrorHandler'
import { Alert, CircularProgress } from '@mui/material'
import { Header } from '@layout/CustomerLayout/Header'
import { useAdminContext } from '@pages/Admin/AdminContext'
import { BookingContact } from '@pages/Customer/Common/BookingContact'
import { BookingStyled } from './Booking.styles'
import { FreeOptions } from './FreeOptions'
import { HouseInfo } from './HouseInfo'
import { PlanImages } from './HouseInfo/HouseImages/PlanImages'
import { Personalization } from './Personalization'
import { usePersonalizationStore } from './usePersonalizationStore'

type BookingParams = Readonly<{
  houseId: HouseId
}>

export const Booking: React.FC = () => {
  const { t } = useTranslation()
  const params = useParams<BookingParams>()
  const defaultErrorHandler = useDefaultErrorHandler()
  const { isLoggedIn } = useAdminContext()

  const setAvailableHouseOptions = usePersonalizationStore((s) => s.setAvailableHouseOptions)
  const availableHouseOptionValueIds = usePersonalizationStore((s) =>
    getSortedOptionValueIds(s.availableHouseOptions.options)
  )

  const getBookingItem = useGetBookingItem(params.houseId ?? '', {
    onError: defaultErrorHandler,
    enabled: hasValue(params.houseId)
  })

  React.useEffect(() => {
    if (
      hasValue(getBookingItem.data) &&
      !eqOptionValueIdArray.equals(getSortedOptionValueIds(getBookingItem.data.options), availableHouseOptionValueIds)
    ) {
      setAvailableHouseOptions({
        options: bookingItem.options,
        styles: bookingItem.styles,
        basePrice: parseIntNanSafe(bookingItem.basePrice) ?? 0
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBookingItem.data])

  if (getBookingItem.isLoading) {
    return <CircularProgress />
  }

  if (!hasValue(getBookingItem.data) || !hasValue(params.houseId)) {
    return null
  }

  const bookingItem = getBookingItem.data
  const freeOptions = bookingItem.freeOptions

  return (
    <BookingStyled>
      {isLoggedIn ? (
        <Alert severity="info">{t('pages.booking.messages.booking_by_admin')}</Alert>
      ) : (
        <Header bgType="light" autoHide />
      )}
      <HouseInfo bookingItem={bookingItem} />
      {bookingItem.planImages.length > 0 && <PlanImages images={bookingItem.planImages} />}
      {freeOptions.length > 0 && <FreeOptions houseStatus={bookingItem.status} freeOptions={freeOptions} />}
      <Personalization houseId={params.houseId} />
      {!isLoggedIn && <BookingContact />}
    </BookingStyled>
  )
}
