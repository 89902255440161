import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { useStoreWithEqualityFn } from 'zustand/traditional'
import { isApiError } from '@digital-magic/react-common/lib/api'
import { OptionalType, hasValue, isEmpty } from '@digital-magic/ts-common-utils'
import { routes } from '@constants/routes'
import { RequestErrorHandler } from '@api/types'
import { IdObject } from '@api/endpoints/types'
import { useCreateBooking } from '@api/endpoints/bookings/api'
import { HouseId } from '@api/endpoints/buildings/houses'
import { eqOptionValueIdArray } from '@api/endpoints/buildings/options'
import { EmailVerificationSessionId, useStartEmailVerification } from '@api/endpoints/public'
import { useDefaultErrorHandler } from '@hooks/useDefaultErrorHandler'
import { useGetLanguage } from '@hooks/useGetLanguage'
import { useVerificationSessionStore } from '@stores/useVerificationSessionStore'
import { useSnackbar } from 'notistack'
import { useAdminContext } from '@pages/Admin/AdminContext'
import { optionItemId } from '@pages/Customer/Booking/Personalization/Options/OptionItem'
import { EmailVerification } from '@pages/Customer/EmailVerification/EmailVerification'
import { ContactsFormState } from '../../types'
import { usePersonalizationStore } from '../../usePersonalizationStore'
import { ContactsForm } from './ContactsForm'

type Props = Readonly<{
  houseId: HouseId
}>

export const Contacts: React.FC<Props> = ({ houseId }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const { isLoggedIn } = useAdminContext()
  const defaultErrorHandler = useDefaultErrorHandler()
  const language = useGetLanguage()
  const { verificationSessionId, setVerificationSessionId, resetVerificationSessionId } = useVerificationSessionStore()
  const customer = usePersonalizationStore((s) => s.customer)
  const setCustomer = usePersonalizationStore((s) => s.setCustomer)
  const selectedLayoutTypeStyleId = usePersonalizationStore((s) => s.selectedLayoutTypeStyleId)
  const selectedLayoutTypeOptionValueIds = useStoreWithEqualityFn(
    usePersonalizationStore,
    (s) => s.selectedOptions.flatMap((o) => o.values.map((v) => v.layoutTypeOptionValueId)),
    eqOptionValueIdArray.equals
  )
  const setupHighlightedOptions = usePersonalizationStore((s) => s.setupHighlightedOptions)
  const resetPersonalization = usePersonalizationStore((s) => s.reset)

  const onVerificationError: RequestErrorHandler = () => {
    resetVerificationSessionId()
    startEmailVerification.reset()
  }

  const onStartEmailVerificationError: RequestErrorHandler = (e) => {
    onVerificationError(e)
    defaultErrorHandler(e)
  }

  const startEmailVerification = useStartEmailVerification({ onError: onStartEmailVerificationError })

  const onCreateBookingError: RequestErrorHandler = (e) => {
    // TODO: Improve error handling
    if (isApiError(e) && e.code === 'AlreadyExistsError') {
      snackbar.enqueueSnackbar(t('pages.booking.form.booking_exists'))
    } else {
      resetVerificationSessionId()
      defaultErrorHandler(e)
    }
  }

  const onCreatedBookingSuccess = (response: IdObject): void => {
    resetPersonalization()
    navigate(generatePath(routes.Customer.BookingSummary, { bookingId: response.id }))
  }

  const createBooking = useCreateBooking({ onError: onCreateBookingError, onSuccess: onCreatedBookingSuccess })

  const validateOptions = (): boolean => {
    const firstErrorOptionId = setupHighlightedOptions()
    if (firstErrorOptionId) {
      // TODO: Avoid using ID attributes
      const firstErrorEl = document.getElementById(optionItemId(firstErrorOptionId))
      firstErrorEl?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    return isEmpty(firstErrorOptionId)
  }

  const handleSubmit = (formValues: ContactsFormState): void => {
    if (validateOptions()) {
      setCustomer(formValues)
      if (isLoggedIn || verificationSessionId) {
        completeSubmit(verificationSessionId, formValues)
      } else {
        startEmailVerification.mutate({ emailAddress: formValues.email, language })
      }
    }
  }

  const completeSessionVerification = (): void => {
    if (hasValue(startEmailVerification.data) && hasValue(customer)) {
      setVerificationSessionId(startEmailVerification.data.id)
      completeSubmit(startEmailVerification.data.id, customer)
    }
  }

  const completeSubmit = (
    verificationSessionId: OptionalType<EmailVerificationSessionId>,
    customer: ContactsFormState
  ): void => {
    startEmailVerification.reset()
    createBooking.mutate({
      verificationSessionId,
      houseId,
      layoutTypeStyleId: selectedLayoutTypeStyleId,
      options: selectedLayoutTypeOptionValueIds,
      customer: { ...customer, language: language }
    })
  }

  const loading = createBooking.isLoading || startEmailVerification.isLoading

  return isLoggedIn ? (
    <ContactsForm defaultValues={customer} loading={loading} onSubmit={handleSubmit} />
  ) : isEmpty(startEmailVerification.data) ? (
    <ContactsForm defaultValues={customer} loading={loading} onSubmit={handleSubmit} />
  ) : (
    <EmailVerification
      verificationSessionId={startEmailVerification.data.id}
      onSuccess={completeSessionVerification}
      onError={onVerificationError}
      loading={loading}
    />
  )
}
