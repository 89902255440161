import { SvgIconProps } from '@mui/material'

export const LongArrowDownIcon = (props: SvgIconProps): React.ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="2.363em" viewBox="0 0 18.538 43.808" {...props}>
      <g id="Group_1" data-name="Group 1" transform="translate(-662.106 -496.5)">
        <path
          id="Path_3"
          data-name="Path 3"
          d="M0,20V63.5"
          transform="translate(671.375 476.5)"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
        />
        <path
          id="Path_2"
          data-name="Path 2"
          d="M5,0l8.875,11.372"
          transform="translate(657.5 528.628)"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
        />
        <path
          id="Path_1"
          data-name="Path 1"
          d="M8.875,0,0,11.372"
          transform="translate(671.375 528.628)"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
        />
      </g>
    </svg>
  )
}
