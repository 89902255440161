import * as React from 'react'
import { hasValue } from '@digital-magic/ts-common-utils'
import { ImageFileEntity, fileUrl, getLargeThumbnailId } from '@api/endpoints/files'
import { ImageGallery, ImageItem } from '@controls/ImageGallery'
import { ImageGalleryWithLayoutStyled } from './ImageGalleryWithLayout.style'

type Props = Readonly<{
  previewCount: number
  primaryImage?: ImageFileEntity
  images: ReadonlyArray<ImageFileEntity>
  galleryName: string
  className?: string
  secondaryImagesOverlayAlwaysVisible?: boolean
}>

export const ImageGalleryWithLayout: React.FC<Props> = ({
  previewCount,
  primaryImage,
  images,
  galleryName,
  className,
  secondaryImagesOverlayAlwaysVisible
}) => {
  const previewImages = React.useMemo(() => images.slice(0, previewCount), [images, previewCount])
  const noPreviewImages = React.useMemo(() => images.slice(previewCount), [images, previewCount])

  return (
    <ImageGalleryWithLayoutStyled previewCount={previewCount} className={className}>
      <ImageGallery selector={`.${galleryName}`}>
        <div className="gallery-wrapper">
          <div className="gallery-grid">
            {hasValue(primaryImage) && (
              <div className="primary-image">
                <ImageItem src={fileUrl(getLargeThumbnailId(primaryImage))} className={galleryName} />
              </div>
            )}
            <div className="small-previews-wrapper">
              <div className="small-previews">
                {previewImages.map((img, index) => (
                  <ImageItem
                    key={index}
                    src={fileUrl(getLargeThumbnailId(img))}
                    className={galleryName}
                    overlayAlwaysVisible={secondaryImagesOverlayAlwaysVisible}
                  />
                ))}
              </div>
            </div>
            <div className="no-preview">
              {noPreviewImages.map((img, index) => (
                <ImageItem key={index} src={fileUrl(getLargeThumbnailId(img))} className={galleryName} />
              ))}
            </div>
          </div>
        </div>
      </ImageGallery>
    </ImageGalleryWithLayoutStyled>
  )
}
