import * as React from 'react'
import { HighlightedTextStyled, HighlightedTitleStyled } from '@pages/Customer/Common/Higlighted/styles'

export const HighlightedText: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <HighlightedTextStyled>{children}</HighlightedTextStyled>
}

export const HighlightedTitle: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <HighlightedTitleStyled>{children}</HighlightedTitleStyled>
}
