import * as React from 'react'
import { useStoreWithEqualityFn } from 'zustand/traditional'
import { HouseOptionView } from '@api/endpoints/buildings/houses'
import { OptionId, eqOptionValueLikeArrayByOptionValueId } from '@api/endpoints/buildings/options'
import { useTranslatedValue } from '@hooks/useTranslatedValue'
import classNames from 'classnames'
import { ExpandMore } from '@mui/icons-material'
import { Box, Collapse } from '@mui/material'
import { Text } from '@controls/Text'
import { CircleCheckIcon } from '@icons/CircleCheckIcon'
import { usePersonalizationStore } from '../../../usePersonalizationStore'
import { OptionItemStyled } from './OptionItem.styles'
import { OptionValueItem } from './OptionValueItem'

type OptionItemProps = Readonly<{
  option: HouseOptionView
}>

export const optionItemId = (optionId: OptionId): string => `optionItem:${optionId}`

export const OptionItem: React.FC<OptionItemProps> = ({ option }) => {
  const translateValue = useTranslatedValue()
  const selectedOptionValues = useStoreWithEqualityFn(
    usePersonalizationStore,
    (s) => s.selectedOptions.find((v) => v.optionId === option.optionId)?.values ?? [],
    eqOptionValueLikeArrayByOptionValueId.equals
  )
  const isHighlighted = usePersonalizationStore((s) => s.highlightedOptionIds.includes(option.optionId))
  const [open, setOpen] = React.useState(false)

  const toggleOpen = (): void => setOpen((o) => !o)

  return (
    <OptionItemStyled id={optionItemId(option.optionId)} className={classNames({ open, highlighted: isHighlighted })}>
      <button className="option-toggle" onClick={toggleOpen}>
        <Text weight="regular" size="size-16">
          {translateValue(option.name) + ' '}
          {!option.multiValue && (
            // eslint-disable-next-line i18next/no-literal-string
            <Text color="error" fontWeight="bold">
              *
            </Text>
          )}
        </Text>
        <Box display="flex" alignItems="center" columnGap={2}>
          {selectedOptionValues.length > 0 && <CircleCheckIcon className="has-values-icon" />}
          <ExpandMore className="expand-icon" />
        </Box>
      </button>
      <Collapse in={open}>
        <div className="option-content">
          {option.values.map((v, i) => (
            <OptionValueItem
              key={i}
              optionValue={v}
              multiValue={option.multiValue}
              optionId={option.optionId}
              checked={selectedOptionValues.some((sv) => sv.optionValueId === v.optionValueId)}
            />
          ))}
        </div>
      </Collapse>
    </OptionItemStyled>
  )
}
