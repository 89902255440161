import styled, { css } from 'styled-components'

export const OptionsStyled = styled('div')(
  ({ theme }) => css`
    padding: ${theme.spacing(8, 0)};
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing(1)};
  `
)
