import * as React from 'react'
import { LayoutTypeImages } from '@api/endpoints/buildings/layoutTypes'
import { ImageGalleryWithLayout } from '@controls/ImageGalleryWithLayout/ImageGalleryWithLayout'

type Props = Readonly<{
  images: LayoutTypeImages
  className?: string
}>

export const HouseImages: React.FC<Props> = ({ images, className }) => {
  const mainImage = React.useMemo(() => images.find((v) => v.primaryImage) ?? images[0], [images])
  const otherImages = React.useMemo(
    () => images.filter((v) => !v.primaryImage).sort((a, b) => a.orderNumber - b.orderNumber),
    [images]
  )

  return (
    <ImageGalleryWithLayout
      previewCount={1}
      primaryImage={mainImage}
      images={otherImages}
      galleryName="house-image"
      secondaryImagesOverlayAlwaysVisible
      className={className}
    />
  )
}
