import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { HtmlInputChangeEventHandler } from '@digital-magic/react-common'
import { hasValue, parseIntNanSafe } from '@digital-magic/ts-common-utils'
import { priceCurrency } from '@constants/configuration'
import { HouseStyleView } from '@api/endpoints/buildings/houses'
import { fileUrl, getLargeThumbnailId } from '@api/endpoints/files'
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter'
import { useTranslatedFileId } from '@hooks/useTranslatedFileId'
import classNames from 'classnames'
import { Box, Grid } from '@mui/material'
import { DownloadLinkByFileId } from '@controls/DownloadLinkByFileId'
import { ImageGallery, ImageItem } from '@controls/ImageGallery'
import { Text } from '@controls/Text'
import { CheckIcon } from '@icons/CheckIcon'
import DownloadPdf from '@icons/DownloadPdf.png'
import { usePersonalizationStore } from '../../usePersonalizationStore'
import { StylesStyled } from './Styles.styles'

type Props = {
  disabledControls: boolean
}

export const Styles: React.FC<Props> = ({ disabledControls }) => {
  const { t } = useTranslation()
  const translateFileId = useTranslatedFileId()
  const formatCurrency = useCurrencyFormatter()
  const availableHouseStyles = usePersonalizationStore((s) => s.availableHouseOptions.styles)
  const selectedLayoutTypeStyleId = usePersonalizationStore((s) => s.selectedLayoutTypeStyleId)
  const setSelectedLayoutTypeStyleId = usePersonalizationStore((s) => s.setSelectedLayoutTypeStyleId)

  const onStyleSelect =
    (style: HouseStyleView): HtmlInputChangeEventHandler =>
    (e) => {
      if (e.target.checked) {
        setSelectedLayoutTypeStyleId(style.layoutTypeStyleId)
      }
    }

  return (
    <StylesStyled container columnSpacing={{ xs: 6, md: 8 }} rowSpacing={8}>
      {availableHouseStyles.map((style) => {
        const mainImage = style.images.find((v) => v.primaryImage) ?? style.images[0]
        const restImages = style.images.filter((v) => !v.primaryImage)
        const price = formatCurrency(parseIntNanSafe(style.additionalPrice) ?? 0, priceCurrency)

        return (
          <Grid
            key={style.layoutTypeStyleId}
            item
            xs={12}
            md={4}
            className={classNames({ 'style-item': true, disabled: disabledControls })}
          >
            <input
              type="radio"
              name="style"
              id={`style${style.styleType}`}
              value={style.styleType}
              onChange={onStyleSelect(style)}
              checked={style.layoutTypeStyleId === selectedLayoutTypeStyleId}
            />
            <label htmlFor={`style${style.styleType}`}>
              <ImageGallery selector=".style-img">
                {mainImage && <ImageItem src={fileUrl(getLargeThumbnailId(mainImage))} className="style-img" />}
                {restImages.map((img, index) => (
                  <ImageItem key={index} src={fileUrl(getLargeThumbnailId(img))} hidden className="style-img" />
                ))}
              </ImageGallery>
              <Box mt={2}>
                <Box display="flex" justifyContent="space-between">
                  <Text size="size-14" weight="regular">
                    {t('pages.booking.personalization.styling_package')}
                  </Text>
                  <Text size="size-14" weight="regular">
                    {price}
                  </Text>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Text size="size-30" weight="regular">
                    {t(`enums.style_type.${style.styleType}`)}
                  </Text>
                  {hasValue(style.documentId) && (
                    <DownloadLinkByFileId fileId={translateFileId(style.documentId)}>
                      <img src={DownloadPdf} title="Download PDF" alt="Download PDF" height={32} />
                    </DownloadLinkByFileId>
                  )}
                  <CheckIcon className="check-icon" />
                </Box>
              </Box>
            </label>
          </Grid>
        )
      })}
    </StylesStyled>
  )
}
