import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { pipe } from 'fp-ts/function'
import { hasValue, map } from '@digital-magic/ts-common-utils'
import { priceCurrency } from '@constants/configuration'
import { toSquareMeters } from '@utils/formatting-utils'
import { BookingItemView } from '@api/endpoints/buildings/houses'
import { EnergyClass, useGetEnergyClasses } from '@api/endpoints/settings'
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter'
import { useDefaultErrorHandler } from '@hooks/useDefaultErrorHandler'
import { useTranslatedValue } from '@hooks/useTranslatedValue'
import { DetailListItem } from '@controls/DetailList'
import { Link } from '@controls/Link'
import { HighlightedText } from '@pages/Customer/Common/Higlighted'

type UseHouseDataResult = Readonly<{
  address: string
  primaryList: ReadonlyArray<DetailListItem>
  secondaryList: ReadonlyArray<DetailListItem>
}>

const isIncludedTranslationBuilder =
  (t: TFunction) =>
  (value: boolean): string =>
    value
      ? t('pages.booking_summary.house_details.messages.yes_included')
      : t('pages.booking_summary.house_details.messages.no_not_included')

export const useHouseData = (bookingItem: BookingItemView): UseHouseDataResult => {
  const { t } = useTranslation()
  const formatCurrency = useCurrencyFormatter()
  const translateValue = useTranslatedValue()
  const defaultErrorHandler = useDefaultErrorHandler()
  const getEnergyClasses = useGetEnergyClasses({ onError: defaultErrorHandler })

  const address = React.useMemo(
    () => `${bookingItem.building.address}${bookingItem.apartmentNumber ? ` - ${bookingItem.apartmentNumber}` : ''}`,
    [bookingItem]
  )

  const primaryList: ReadonlyArray<DetailListItem> = React.useMemo(
    () => [
      {
        label: t('pages.booking.house_info.house_type.label'),
        value: t(`pages.booking.house_info.house_type.enum.${bookingItem.building.type.code}`)
      },
      { label: t('pages.booking.house_info.floor_count'), value: bookingItem.building.floorsCount },
      { label: t('pages.booking.house_info.room_count'), value: bookingItem.roomsCount },
      { label: t('pages.booking.house_info.indoor_square'), value: toSquareMeters(bookingItem.indoorSquare) },
      {
        label: t('pages.booking.house_info.price'),
        value: <HighlightedText>{formatCurrency(Number(bookingItem.basePrice), priceCurrency)}</HighlightedText>
      }
    ],
    [t, bookingItem, formatCurrency]
  )

  const secondaryList: ReadonlyArray<DetailListItem> = React.useMemo(
    () =>
      pipe(isIncludedTranslationBuilder(t), (isIncludedValue) =>
        [
          {
            label: t('pages.booking.house_info.facade_color'),
            value: t(`enums.facade_color.${bookingItem.building.facadeColor}`)
          },
          {
            label: t('pages.booking.house_info.cadastre_number'),
            value: (
              <Link href={`https://xgis.maaamet.ee/ky/${bookingItem.cadastreNumber}`} target="_blank" rel="noopener">
                {bookingItem.cadastreNumber}
              </Link>
            )
          },
          hasValue(bookingItem.floor)
            ? {
                label: t('pages.booking.house_info.floor'),
                value: bookingItem.floor
              }
            : undefined,
          { label: t('pages.booking.house_info.bedroom_count'), value: bookingItem.bedroomsCount },
          {
            label: t('pages.booking.house_info.has_sauna'),
            value: isIncludedValue(bookingItem.hasSauna)
          },
          {
            label: t('pages.booking.house_info.is_interior_included'),
            value: isIncludedValue(bookingItem.isInteriorIncluded)
          },
          {
            label: t('pages.booking.house_info.is_sanitary_included'),
            value: isIncludedValue(bookingItem.isSanitaryIncluded)
          },
          {
            label: t('pages.booking.house_info.energy_class'),
            value:
              map((v: EnergyClass) => translateValue(v.title))(
                getEnergyClasses.data?.find((v) => v.id === bookingItem.energyClassId)
              ) ?? '-'
          },
          { label: t('pages.booking.house_info.outdoor_square'), value: toSquareMeters(bookingItem.outdoorSquare) },
          { label: t('pages.booking.house_info.storeroom_square'), value: toSquareMeters(bookingItem.storeRoomSquare) },
          { label: t('pages.booking.house_info.terrace_square'), value: toSquareMeters(bookingItem.terraceSquare) }
        ].filter(hasValue)
      ),
    [t, translateValue, bookingItem, getEnergyClasses.data]
  )

  return {
    address,
    primaryList,
    secondaryList
  }
}
