import styled, { css } from 'styled-components'
import { darken } from '@mui/material'
import { SectionContainerStyled } from '@pages/Customer/Customer.styles'

export const HouseInfoStyled = styled(SectionContainerStyled)(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;

    button {
      background: ${theme.colors.brown};
      color: ${theme.colors.white};
      white-space: nowrap;
      border: none;

      &,
      .btn-loader {
        color: ${theme.colors.white};
      }

      &:hover,
      &:focus {
        background: ${darken(theme.colors.brown, 0.1)};
      }
    }

    .left-block {
      ${theme.mixins.flexColumnSpaceBetween}
      padding: ${theme.spacing(0, 8, 0, 0)};
      flex-basis: 40%;

      .viimsi-logo {
        font-size: 2.5em;
        margin-bottom: ${theme.spacing(5)};
      }

      .back-link {
        display: flex;
        align-items: center;
        text-decoration: none;
        column-gap: ${theme.spacing(3)};
        margin-left: ${theme.spacing(1.5)};
        margin-bottom: ${theme.spacing(3)};
        color: inherit;

        svg {
          font-size: 0.75em;
          transform: rotate(90deg);
        }
      }
    }

    .right-block {
      flex-basis: 60%;

      .house-image {
        height: 60vh;
      }
    }

    ${theme.breakpoints.between('md', 'lg')} {
      .left-block {
        flex-basis: 45%;
        padding: ${theme.spacing(0, 4, 0, 0)};
      }
      .right-block {
        flex-basis: 55%;
      }
    }

    ${theme.breakpoints.down('md')} {
      flex-direction: column;

      .left-block {
        padding: ${theme.spacing(0, 0, 4, 0)};
        order: 1000;

        .viimsi-logo {
          position: absolute;
          z-index: 100;
          top: ${theme.spacing(5)};
          left: ${theme.spacing(5)};
        }

        .back-link {
          display: none;
        }
      }
    }
  `
)
