import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@controls/Text'
import { usePersonalizationStore } from '../../usePersonalizationStore'
import { OptionItem } from './OptionItem'
import { OptionsStyled } from './Options.styles'

export const Options: React.FC = () => {
  const { t } = useTranslation()
  const availableHouseOptions = usePersonalizationStore((s) => s.availableHouseOptions.options)

  return (
    <OptionsStyled>
      {availableHouseOptions.map((o) => (
        <OptionItem key={o.optionId} option={o} />
      ))}
      <Text mt={3}>
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <Text color="error" fontWeight="bold">
          *
        </Text>
        {' - ' + t('pages.booking.personalization.options.messages.required_fields')}
      </Text>
    </OptionsStyled>
  )
}
