import styled, { css } from 'styled-components'

export const OptionValueItemStyled = styled('div')(
  ({ theme }) => css`
    .option-value-title {
      background: ${theme.mixins.opacity(theme.colors.brown, 0.1)};
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: ${theme.spacing(1.25, 2, 1.25, 1.25)};
    }

    .lg-react-element {
      margin-top: ${theme.spacing(1.5)};
      display: flex;
      flex-flow: wrap;
      column-gap: ${theme.spacing(2)};
      row-gap: ${theme.spacing(2)};

      .option-value-image {
        ${theme.mixins.square(theme.spacing(19))}
      }
    }
  `
)
