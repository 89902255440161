import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { routes } from '@constants/routes'
import { BookingItemView } from '@api/endpoints/buildings/houses'
import { Link } from '@controls/Link'
import { Text } from '@controls/Text'
import { LongArrowDownIcon } from '@icons/LongArrowDownIcon'
import { ViimsiLogo } from '@icons/ViimsiLogo'
import { HouseDetails } from './HouseDetails'
import { HouseImages } from './HouseImages'
import { HouseInfoStyled } from './HouseInfo.styles'

type Props = Readonly<{
  bookingItem: BookingItemView
}>

export const HouseInfo: React.FC<Props> = ({ bookingItem }) => {
  const { t } = useTranslation()

  return (
    <HouseInfoStyled bgType="light">
      <div className="left-block">
        <div>
          <ViimsiLogo className="viimsi-logo" />
          <Link to={routes.Customer.Prices} className="back-link">
            <LongArrowDownIcon />
            <Text weight="regular">{t('global.buttons.back')}</Text>
          </Link>
        </div>
        <HouseDetails bookingItem={bookingItem} />
      </div>
      <HouseImages images={bookingItem.images} className="right-block" />
    </HouseInfoStyled>
  )
}
