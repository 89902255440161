import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { HousePlanImages } from '@api/endpoints/buildings/houses'
import { ImageGalleryWithLayout } from '@controls/ImageGalleryWithLayout/ImageGalleryWithLayout'
import { TitledSection } from '@pages/Customer/Booking/TitledSection'
import { SectionContainerStyled } from '@pages/Customer/Customer.styles'

type Props = Readonly<{
  images: HousePlanImages
}>

export const PlanImages: React.FC<Props> = ({ images }) => {
  const planImages = React.useMemo(() => images.concat().sort((a, b) => a.orderNumber - b.orderNumber), [images])
  const { t } = useTranslation()

  return (
    <SectionContainerStyled bgType="light">
      <TitledSection title={t('pages.booking.plan_images.title')}>
        <ImageGalleryWithLayout
          previewCount={images.length > 1 ? 2 : 1}
          images={planImages}
          galleryName="house-plan-image"
        />
      </TitledSection>
    </SectionContainerStyled>
  )
}
