import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { priceCurrency } from '@constants/configuration'
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter'
import { Text } from '@controls/Text'
import { HighlightedText } from '@pages/Customer/Common/Higlighted'
import { usePersonalizationStore } from '../../usePersonalizationStore'
import { TotalPriceStyled } from './styles'

export const TotalPrice: React.FC = () => {
  const { t } = useTranslation()
  const totalPrice = usePersonalizationStore((s) => s.totalPrice)

  const formatCurrency = useCurrencyFormatter()

  return (
    <TotalPriceStyled bgType="light">
      <Text color="brown" weight="regular" size="size-16">
        {t('pages.booking.personalization.footer.price_label')}
      </Text>
      <HighlightedText>{formatCurrency(totalPrice, priceCurrency)}</HighlightedText>
    </TotalPriceStyled>
  )
}
