import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { HtmlMouseButtonEventHandler } from '@digital-magic/react-common'
import { BookingItemView } from '@api/endpoints/buildings/houses'
import { Box } from '@mui/material'
import { Button } from '@controls/Button'
import { DetailList } from '@controls/DetailList'
import { useHouseData } from '@pages/Customer/Booking/HouseInfo/HouseDetails/useHouseData'
import { HighlightedTitle } from '@pages/Customer/Common/Higlighted'
import { HouseDetailsStyled } from './styles'
import { hasValue } from '@digital-magic/ts-common-utils'
import virtualTourImage from '@icons/virtual_tour.png'

type Props = Readonly<{
  bookingItem: BookingItemView
}>

export const HouseDetails: React.FC<Props> = ({ bookingItem }) => {
  const { t } = useTranslation()
  const houseData = useHouseData(bookingItem)

  const onChoseExtrasClick: HtmlMouseButtonEventHandler = (e) => {
    e.preventDefault()
    e.currentTarget.blur()
    document.getElementById('booking_personalization')?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <HouseDetailsStyled>
      <HighlightedTitle>{houseData.address}</HighlightedTitle>
      <DetailList items={houseData.primaryList} alignValue="right" />
      {hasValue(bookingItem.virtualTourUrl) && (
        <Box mt={3} mb={3}>
          <a href={bookingItem.virtualTourUrl} target="_blank" className="virtual-tour-link">
            <Button
              fullWidth
              className="virtual-tour-button"
              startIcon={<img src={virtualTourImage} alt="Virtual Tour" />}
            >
              {/* TODO: Make a separate translation */}
              {t('pages.landing.houses.virtual_tour')}
            </Button>
          </a>
        </Box>
      )}
      <Box mt={4} mb={4.2}>
        <Button fullWidth onClick={onChoseExtrasClick}>
          {t('pages.booking.house_info.buttons.chose_extras')}
        </Button>
      </Box>
      <DetailList items={houseData.secondaryList} alignValue="right" />
    </HouseDetailsStyled>
  )
}
