import styled, { css } from 'styled-components'
import { SectionContainerStyled } from '@pages/Customer/Customer.styles'

export const FreeOptionsStyled = styled(SectionContainerStyled)(
  ({ theme }) => css`
    padding-bottom: ${theme.spacing(8)};

    ${theme.breakpoints.down('md')} {
      padding-bottom: ${theme.spacing(3)};
    }

    .option-item {
      ${theme.mixins.flexCenterV}

      column-gap: ${theme.spacing(1)};

      svg {
        font-size: ${theme.global.typography.size['size-20']};
        color: ${theme.colors.brown};
      }
    }
  `
)
