import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { HouseFreeOptionView, HouseStatus } from '@api/endpoints/buildings/houses'
import { useTranslatedValue } from '@hooks/useTranslatedValue'
import { Grid } from '@mui/material'
import { Text } from '@controls/Text'
import { TitledSection } from '@pages/Customer/Booking/TitledSection'
import { CircleCheckIcon } from '@icons/CircleCheckIcon'
import { FreeOptionsStyled } from './FreeOptions.styles'

type Props = {
  houseStatus: HouseStatus
  freeOptions: ReadonlyArray<HouseFreeOptionView>
}

export const FreeOptions: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const translateValue = useTranslatedValue()

  const freeOptions = React.useMemo(
    () => (props.houseStatus !== 'Available' ? props.freeOptions : props.freeOptions.filter((o) => !o.disabled)),
    [props.houseStatus, props.freeOptions]
  )

  return (
    <FreeOptionsStyled bgType="light">
      <TitledSection title={t('pages.booking.free_options.title')}>
        <Grid container rowSpacing={2} columnSpacing={2}>
          {freeOptions.map((o) => (
            <Grid key={o.layoutTypeFreeOptionId} item xs={12} sm={6} className="option-item">
              <CircleCheckIcon />
              <Text size={{ xs: 'size-14', lg: 'size-16' }} weight="regular">
                {translateValue(o.name)}
              </Text>
            </Grid>
          ))}
        </Grid>
      </TitledSection>
    </FreeOptionsStyled>
  )
}
