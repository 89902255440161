import styled, { css } from 'styled-components'

export const TitledSectionStyle = styled('div')(
  ({ theme }) => css`
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 0; // TODO: Correct spacing
    grid-row-gap: 0;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: auto;

    ${theme.breakpoints.down('lg')} {
      grid-template-columns: auto;
      grid-template-rows: auto auto;
    }

    .title {
      top: ${theme.spacing(5)};

      h2 {
        position: sticky;
        top: ${theme.spacing(12)};
        padding: 0;
        margin: 0;
        color: #333;
        font-size: ${theme.global.typography.size['size-34']};
        font-weight: ${theme.typography.fontWeightRegular};
        line-height: 36px;
        max-width: 640px;

        ${theme.breakpoints.down('sm')} {
          font-size: ${theme.global.typography.size['size-22']};
        }
      }
    }

    .content {
      padding: ${theme.spacing(0, 0, 0, 6)};
      position: static;

      ${theme.breakpoints.down('lg')} {
        padding: ${theme.spacing(6, 0)};
      }
    }
  `
)
