import styled, { css } from 'styled-components'

export const OptionItemStyled = styled('div')(
  ({ theme }) => css`
    .option-toggle,
    .option-content {
      padding: ${theme.spacing(2.5)};
      color: ${theme.colors.black};
    }

    .option-toggle {
      width: 100%;
      border: none;
      background: ${theme.mixins.opacity(theme.colors.brown, 0.05)};
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: all 0.2s;
      cursor: pointer;
      font-family: inherit;

      .expand-icon {
        font-size: ${theme.global.typography.size['size-24']};
        transition: all 0.2s;
      }

      .has-values-icon {
        color: ${theme.colors.brown};
        font-size: ${theme.global.typography.size['size-18']};
      }
    }

    &.open {
      .option-toggle {
        background: ${theme.colors.brown};
        color: ${theme.colors.white};

        .expand-icon {
          transform: rotate(180deg);
        }
      }
    }

    &.highlighted .option-toggle {
      background: ${theme.mixins.opacity(theme.colors.error, 0.05)};
      color: ${theme.colors.error};
      outline: 1px solid ${theme.colors.error};
    }

    .option-content {
      background: ${theme.colors.white};
      display: flex;
      flex-direction: column;
      row-gap: ${theme.spacing(1)};
    }
  `
)
