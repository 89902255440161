import styled, { css } from 'styled-components'
import { SectionContainerStyled } from '@pages/Customer/Customer.styles'

export const TotalPriceStyled = styled(SectionContainerStyled)(
  ({ theme }) => css`
    position: sticky;
    bottom: 0;
    background: ${theme.colors.white};
    border-top: 1px solid ${theme.colors.grey500};

    display: flex;
    align-items: center;
    justify-content: flex-end;

    column-gap: ${theme.spacing(4)};

    ${theme.breakpoints.down('md')} {
      position: static;
      column-gap: ${theme.spacing(2)};
    }
  `
)
