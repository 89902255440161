import styled, { css } from 'styled-components'

type Props = Readonly<{
  previewCount: number
}>

export const ImageGalleryWithLayoutStyled = styled('div')<Props>(
  ({ previewCount }) => css`
    .gallery-wrapper {
      display: flex;
      flex-direction: column;

      .gallery-grid {
        display: grid;
        flex-direction: column;
        justify-content: space-between;
        grid-auto-columns: 1fr;
        grid-column-gap: 16px;
        grid-row-gap: 16px;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;

        img {
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          display: block;
          object-fit: cover;
          vertical-align: middle;
          align-self: stretch;
        }

        .primary-image img {
          min-width: 100%;
          max-width: 100%;
        }

        .small-previews-wrapper {
          height: 100%;

          .small-previews {
            display: grid;
            height: 100%;
            grid-auto-columns: 1fr;
            grid-column-gap: 16px;
            grid-row-gap: 16px;

            grid-template-columns: ${previewCount > 3 ? '1fr 1fr 1fr 1fr' : previewCount > 1 ? '1fr 1fr' : '1fr'};
            grid-template-rows: auto;

            img {
              min-height: 100%;
              min-width: 100%;
            }
          }
        }

        .no-preview {
          display: none;
        }
      }
    }
  `
)
