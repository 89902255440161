import * as React from 'react'
import { HtmlInputChangeEventHandler } from '@digital-magic/react-common'
import { priceCurrency } from '@constants/configuration'
import { HouseOptionValueView } from '@api/endpoints/buildings/houses'
import { OptionId } from '@api/endpoints/buildings/options'
import { fileUrl, getLargeThumbnailId } from '@api/endpoints/files'
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter'
import { useTranslatedFileId } from '@hooks/useTranslatedFileId'
import { useTranslatedValue } from '@hooks/useTranslatedValue'
import { Box } from '@mui/material'
import { Checkbox } from '@controls/Checkbox'
import { DownloadLinkByFileId } from '@controls/DownloadLinkByFileId'
import { ImageGallery, ImageItem } from '@controls/ImageGallery'
import { Text } from '@controls/Text'
import DownloadPdf from '@icons/DownloadPdf.png'
import { usePersonalizationStore } from '../../../../usePersonalizationStore'
import { OptionValueItemStyled } from './OptionValueItem.styles'

type OptionValueItemProps = Readonly<{
  optionId: OptionId
  optionValue: HouseOptionValueView
  multiValue: boolean
  checked: boolean
}>

export const OptionValueItem: React.FC<OptionValueItemProps> = ({ optionId, optionValue, multiValue, checked }) => {
  const translateValue = useTranslatedValue()
  const translateFileId = useTranslatedFileId()
  const formatCurrency = useCurrencyFormatter()

  const setSelectedOptionValueId = usePersonalizationStore((state) => state.setSelectedOptionValueId)
  const addSelectedOptionValueId = usePersonalizationStore((state) => state.addSelectedOptionValueId)
  const removeSelectedOptionValueId = usePersonalizationStore((state) => state.removeSelectedOptionValueId)
  const removeHighlightedOption = usePersonalizationStore((s) => s.removeHighlightedOption)

  const formattedPrice = formatCurrency(Number(optionValue.price), priceCurrency)
  const checkboxLabel = <Text weight="semibold">{translateValue(optionValue.name)}</Text>

  const onOptionValueSelect: HtmlInputChangeEventHandler = (e) => {
    if (e.target.checked) {
      if (multiValue) {
        addSelectedOptionValueId(optionValue.optionValueId)
      } else {
        setSelectedOptionValueId(optionId, optionValue.optionValueId)
      }
      removeHighlightedOption(optionId)
    } else {
      removeSelectedOptionValueId(optionValue.optionValueId)
    }
  }

  return (
    <OptionValueItemStyled>
      <div className="option-value-title">
        <Checkbox label={checkboxLabel} onChange={onOptionValueSelect} checked={checked} />
        <Text weight="semibold">{formattedPrice}</Text>
      </div>
      {optionValue.description && (
        <Text component="div" weight="regular" py={2.5} pl={6} pr={12}>
          {translateValue(optionValue.description)}
        </Text>
      )}
      {optionValue.documentId && (
        <Box py={2.5} pl={6} pr={12}>
          <DownloadLinkByFileId fileId={translateFileId(optionValue.documentId)}>
            <img src={DownloadPdf} title="Download PDF" alt="Download PDF" height={32} />
          </DownloadLinkByFileId>
        </Box>
      )}
      {optionValue.images.length > 0 && (
        <ImageGallery selector=".option-value-image">
          {optionValue.images
            .concat()
            .sort((a, b) => a.orderNumber - b.orderNumber)
            .map((img, index) => (
              <ImageItem key={index} className="option-value-image" src={fileUrl(getLargeThumbnailId(img))} />
            ))}
        </ImageGallery>
      )}
    </OptionValueItemStyled>
  )
}
