import styled, { FlattenSimpleInterpolation, css } from 'styled-components'
import { Grid, Theme } from '@mui/material'

const inputBackground = (theme: Theme, opacity: number): FlattenSimpleInterpolation => css`
  background: ${theme.mixins.opacity(theme.colors.brown, opacity)};
  box-shadow: 0 0 0 ${theme.spacing(2)} ${theme.mixins.opacity(theme.colors.brown, opacity)};
`

export const StylesStyled = styled(Grid)(
  ({ theme }) => css`
    padding-top: ${theme.spacing(3)};

    .style-item {
      position: relative;

      input {
        position: absolute;
        opacity: 0;
      }

      label {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        transition: all 0.2s;

        &:hover {
          ${inputBackground(theme, 0.05)}
        }

        .check-icon {
          font-size: ${theme.global.typography.size['size-24']};
          color: ${theme.colors.brown};
          opacity: 0;
          transition: all 0.2s;
        }
      }

      input:checked + label {
        ${inputBackground(theme, 0.1)}

        svg {
          opacity: 1;
        }
      }

      &.disabled {
        label {
          cursor: default;
          pointer-events: none;
          &:hover {
            ${inputBackground(theme, 0)}
          }
        }
      }
    }
  `
)
