import styled, { css } from 'styled-components'

export const HouseDetailsStyled = styled('div')(
  ({ theme }) => css`
    .virtual-tour-link:hover {
      text-decoration: none;
    }
    .virtual-tour-button {
      color: ${theme.colors.white};
      background-color: ${theme.colors.brown};
      max-width: 100%;
      border: 0;
      text-transform: capitalize;
    }

    ${theme.breakpoints.down('md')} {
      padding-top: ${theme.spacing(4)};
    }
  `
)
