import styled, { css } from 'styled-components'
import { darken } from '@mui/material'

//import { SectionContainerStyled } from '@pages/Customer/Customer.styles'

export const ContactsFormStyled = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.white};
    padding-bottom: ${theme.spacing(8)};
    display: flex;
    align-items: center;
    flex-direction: column;

    form {
      max-width: 400px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      row-gap: ${theme.spacing(2)};

      button {
        margin-top: ${theme.spacing(3)};
        background: ${theme.colors.brown};
        white-space: nowrap;
        border: none;

        &,
        .btn-loader {
          color: ${theme.colors.white};
        }

        &:hover,
        &:focus {
          background: ${darken(theme.colors.brown, 0.1)};
        }
      }
    }

    ${theme.breakpoints.down('md')} {
      padding-bottom: ${theme.spacing(3)};
    }
  `
)
