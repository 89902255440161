import styled, { css } from 'styled-components'

export const BookingStyled = styled('div')(
  ({ theme }) => css`
    background: ${theme.colors.white};

    .gallery-wrapper .gallery-grid .small-previews-wrapper .small-previews .house-plan-image > img {
      min-width: 100px;
      min-height: 100px;
      max-height: 600px;
      max-width: 400px;
      height: 100%;
      width: 100%;
    }
  `
)
