import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { HouseId } from '@api/endpoints/buildings/houses'
import { Contacts } from '@pages/Customer/Booking/Personalization/Contacts'
import { TitledSection } from '@pages/Customer/Booking/TitledSection'
import { SectionContainerStyled } from '@pages/Customer/Customer.styles'
import { Options } from './Options'
import { PersonalizationStyled } from './Personalization.styles'
import { Styles } from './Styles'
import { Summary } from './Summary'
import { TotalPrice } from './TotalPrice'

type Props = Readonly<{
  houseId: HouseId
}>

export const Personalization: React.FC<Props> = ({ houseId }) => {
  const { t } = useTranslation()
  return (
    <PersonalizationStyled>
      <SectionContainerStyled bgType="dark">
        <TitledSection title={<span id="booking_personalization">{t('pages.booking.personalization.title')}</span>}>
          <Styles disabledControls={false} />
          <Options />
        </TitledSection>
      </SectionContainerStyled>
      <Summary />
      <TotalPrice />
      <SectionContainerStyled bgType="light">
        <TitledSection title={t('pages.booking.form.title')}>
          <Contacts houseId={houseId} />
        </TitledSection>
      </SectionContainerStyled>
    </PersonalizationStyled>
  )
}
